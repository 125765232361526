import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    categories: [],
    categoryDetail: null,
    count: 0
};

const slice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCategoryList(state, action) {
            state.categories = action.payload.categories;
            state.count = action.payload.count;
        },
        setCategoryDetail(state, action) {
            state.categoryDetail = action.payload;
        },
        unsetCategoryDetail(state, action) {
            state.categoryDetail = null;
        }
    }
});

export default slice.reducer;

export function getBlogCategories(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/category/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCategoryList(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getBlogCategory(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/category/get/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCategoryDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function createBlogCategory(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/category/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCategoryDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateBlogCategory(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/category/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setCategoryDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function clearCategoryInStore(data, callback) {
    return () => {
        dispatch(slice.actions.unsetCategoryDetail());
        if (callback) {
            callback();
        }
    };
}
