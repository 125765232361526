// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import logo from 'assets/images/logo.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <Box
            component="img"
            src={logo}
            alt=""
            sx={{
                width: '220px',
                height: '50px',
                [theme.breakpoints.down(1099.95)]: {
                    width: '110px',
                    height: '25px'
                }
            }}
        />
    );
};

export default Logo;
