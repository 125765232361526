import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import PreviewLayout from 'layout/PreviewLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const PreviewPage = Loadable(lazy(() => import('views/preview-page')));

// ==============================|| MAIN ROUTING ||============================== //

const TestingRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <PreviewLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'preview/:type/:id',
            element: <PreviewPage />
        }
    ]
};

export default TestingRoutes;
