import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    posts: [],
    postDetail: null,
    count: 0,
    uploadFilePath: null
};

const slice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPostList(state, action) {
            state.posts = action.payload.posts;
            state.count = action.payload.count;
        },
        setPostDetail(state, action) {
            state.postDetail = action.payload;
        },
        unsetPostDetail(state, action) {
            state.postDetail = null;
        },
        setUploadFilePath(state, action) {
            state.uploadFilePath = action.payload;
        },
        unsetUploadFilePath(state, action) {
            state.uploadFilePath = null;
        }
    }
});

export default slice.reducer;

export function getBlogPosts(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/post/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log(response);
            dispatch(slice.actions.setPostList(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getBlogPost(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/post/get/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPostDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function createBlogPost(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/post/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPostDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function updateBlogPost(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/post/update', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.setPostDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function clearPostInStore(data, callback) {
    return () => {
        dispatch(slice.actions.unsetPostDetail());
        if (callback) {
            callback();
        }
    };
}

export function fileUpload(data, callback) {
    return async () => {
        try {
            // dispatch(slice.actions.unsetUploadFilePath());
            const formData = new FormData();
            formData.append('file', data);

            const response = await axios.post('/api/file/upload', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            dispatch(slice.actions.setUploadFilePath(response.data));
            if (callback) {
                await callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
