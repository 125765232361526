// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| CMS USER MENU ITEMS ||============================== //

const cmsUser = {
    id: 'cms-user-menu',
    type: 'group',
    children: [
        {
            id: 'post-list',
            title: <FormattedMessage id="blog-post" />,
            type: 'item',
            url: '/post-list',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'category-list',
            title: <FormattedMessage id="blog-category" />,
            type: 'item',
            url: '/category-list',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default cmsUser;
