import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const BlogPostPage = Loadable(lazy(() => import('views/blog-post-page')));
const BlogPostDetailPage = Loadable(lazy(() => import('views/blog-post-detail-page')));
const BlogCategoryPage = Loadable(lazy(() => import('views/blog-category-page')));
const BlogCategoryDetailPage = Loadable(lazy(() => import('views/blog-category-detail-page')));
const PermissionDeniedPage = Loadable(lazy(() => import('views/permission-denied-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/post-list',
            element: <BlogPostPage />
        },
        {
            path: '/post/:id',
            element: <BlogPostDetailPage />
        },
        {
            path: '/category-list',
            element: <BlogCategoryPage />
        },
        {
            path: '/category/:id',
            element: <BlogCategoryDetailPage />
        },
        {
            path: '/permission-denied',
            element: <PermissionDeniedPage />
        }
    ]
};

export default MainRoutes;
